import {
  Container,
  Eyebrow,
  Flex,
  Display,
  spacing,
  Body,
  Badge,
  Support,
  Label,
  Icon,
  grey,
  white,
} from '@pelotoncycle/design-system';
import type { ApolloError } from 'apollo-client';
import React from 'react';
import styled from 'styled-components';
import { media } from '@peloton/styles';
import { useOpenCartPanel } from '@ecomm/cart-next/context/CartContext';
import ModalView from '@ecomm/modal/ModalView';
import {
  ScrollFromBottomModalStyles,
  MODAL_CLOSE_TRANSITION_TIME,
} from '@ecomm/modal/ScrollFromBottomModalStyles';
import {
  isProductBundle,
  isProductSparePart,
  isProductWarranty,
} from '@ecomm/product-recommendations/models/checkProductType';
import type { Product } from '@ecomm/product-recommendations/models/Product';
import { isSinglePrice } from '@ecomm/product-recommendations/utils/productUtils';
import type { QuickAddMutation } from '@ecomm/product-recommendations/utils/quick-add/types';
import usePill from '@ecomm/product-recommendations/utils/usePill';
import { useProductRecommendationsCopyKey } from '@ecomm/product-recommendations/utils/useProductRecommendationsCopyKey';
import ImageCarousel from '@ecomm/product-recommendations/Views/Grid/ImageCarousel';
import { PriceRange, SinglePrice } from '@ecomm/product-recommendations/Views/Price';
import AddToCartError from '@page-builder/modules/Overview/ShopDrawers/AddToCartError';
import Instructions from '@page-builder/modules/SpareParts/Instructions/Instructions';
import { useConfigureProductFormData } from '../utils/useConfigureProductFormData';
import { ConfigureProductFormSelections } from './ConfigureProductFormSelections';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  product: Product;
  isInCart: boolean;
  addToCartMutation: QuickAddMutation;
};

export const QuickViewModal: React.FC<React.PropsWithChildren<Props>> = ({
  isOpen,
  onClose,
  product,
  isInCart,
  addToCartMutation,
}) => {
  const { name, factoid, description, factoidEyebrow } = product;

  const [quickAddProduct, { loading, error }] = addToCartMutation;

  const openCartPanel = useOpenCartPanel();
  const {
    displayedPrice,
    selections,
    onFormSubmit,
    toSelectItemHandler,
  } = useConfigureProductFormData({
    product,
    quickAddProduct,
    onClose: () => {
      onClose();
      // align opening of cart panel after the closing of the quickview modal
      // to avoid styling clash
      setTimeout(() => openCartPanel(), MODAL_CLOSE_TRANSITION_TIME + 1);
    },
  });

  const pill = usePill({ isInCart, product });
  const isSparePart = isProductSparePart(product);
  const isWarranty = isProductWarranty(product);

  const deliveryMessage = useProductRecommendationsCopyKey('delivery');
  const freeReturnsText = useProductRecommendationsCopyKey('freeReturns');
  const addToCartCTA = useProductRecommendationsCopyKey('addToCart');
  const factoidHeadingText = useProductRecommendationsCopyKey('factoidHeading');
  const productDetailsTitle = useProductRecommendationsCopyKey('productDetails');
  const addToCartErrorMessage = useProductRecommendationsCopyKey('defaultError');
  const quickAddCTA = useProductRecommendationsCopyKey('quickAdd');

  const shouldDisplayFactoid = !isProductBundle(product) && !isSparePart;

  return (
    <StyledModalView
      width={960}
      isOpen={isOpen}
      contentLabel={'Quick View Modal'}
      className="quick-view-modal"
      contentClassname="scroll-from-bottom-modal"
      closeHandler={onClose}
      data-test-id="prod-rec-quick-view-modal"
    >
      <ScrollFromBottomModalStyles />
      <Flex
        flexDirection={{
          mobile: 'column',
          desktop: 'row',
        }}
        gap={{ mobile: spacing[24], tablet: spacing[40] }}
        alignItems={{
          mobile: 'center',
          desktop: 'flex-start',
        }}
        borderRadius={{ mobile: `${spacing[12]} 0` }}
      >
        <ImageCarousel product={product} isSparePart={isSparePart} />
        <ProductContent flexDirection="column" alignItems="flex-start">
          <Flex
            flexDirection="column"
            alignItems="flex-start"
            gap={spacing[12]}
            margin={{
              mobile: `0`,
              desktop: `${spacing[24]} 0 0 0`,
            }}
          >
            {pill && (
              <Badge variant="horizontal" theme={pill.theme}>
                {pill.text}
              </Badge>
            )}
            <StyledName size="small">{name}</StyledName>
          </Flex>
          <Body size="large" style={{ paddingBottom: `${spacing[40]}` }}>
            {isSinglePrice(displayedPrice) ? (
              <SinglePrice price={displayedPrice} />
            ) : (
              <PriceRange range={displayedPrice} />
            )}
          </Body>
          <ConfigureProductFormSelections
            product={product}
            selections={selections}
            onFormSubmit={onFormSubmit}
            toSelectItemHandler={toSelectItemHandler}
            quickAddCTA={quickAddCTA}
            addToCartCTA={addToCartCTA}
            loading={loading}
            isQuickViewModal={true}
          />
          {error && (
            <ErrorWrapper data-test-id="configure-form-error">
              <AddToCartError
                errorCode={error.name}
                errorMessageOverride={addToCartErrorMessage}
              />
            </ErrorWrapper>
          )}
          <ProductInfo flexDirection="column" gap={spacing[40]}>
            <Flex flexDirection="column" gap={spacing[16]}>
              {isSparePart ? (
                <Instructions productName={product.name} />
              ) : (
                !isWarranty && (
                  <>
                    <Flex gap={spacing[12]}>
                      <Icon height={24} name={'money'} />
                      <Label size="large">{deliveryMessage}</Label>
                    </Flex>
                    <Flex gap={spacing[12]}>
                      <Icon height={24} name={'parcelBox'} />
                      <Label size="large">{freeReturnsText}</Label>
                    </Flex>
                  </>
                )
              )}
            </Flex>
            {shouldDisplayFactoid && (
              <Flex
                backgroundColor={grey[30]}
                padding={spacing[24]}
                flexDirection="column"
                borderRadius={spacing[4]}
              >
                <Eyebrow
                  textAlign={'center'}
                  size="medium"
                  style={{ paddingBottom: `${spacing[8]}` }}
                >
                  {factoidEyebrow || factoidHeadingText}
                </Eyebrow>
                <Support size="medium" textAlign={'center'}>
                  {factoid}
                </Support>
              </Flex>
            )}
            {description && (
              <ProductDetailsContainer
                flexDirection="column"
                justifyContent="flex-start"
                textAlign="left"
                verticalPadding={spacing[24]}
              >
                <Eyebrow size="medium">{productDetailsTitle}</Eyebrow>
                <Container
                  padding={`${spacing[24]} 0 0 0`}
                  maxWidth={{ desktop: '392px' }}
                >
                  <Body size="small">{description}</Body>
                </Container>
              </ProductDetailsContainer>
            )}
          </ProductInfo>
        </ProductContent>
      </Flex>
    </StyledModalView>
  );
};

const StyledModalView = styled(ModalView)`
  background-color: ${white};
  padding: ${spacing[16]} ${spacing[16]} 114px ${spacing[16]};
  border-radius: ${spacing[12]} ${spacing[12]} 0px 0px;

  ${media.tabletXLarge`
    padding: ${spacing[40]} ${spacing[64]} 114px ${spacing[64]};
  `}

  ${media.desktopLarge`
    max-width: 960px;
    max-height: 632px;
    padding: ${spacing[24]} ${spacing[24]} ${spacing[24]} ${spacing[40]};
    border-radius: ${spacing[4]};
  `}
`;

const ProductContent = styled(Flex)`
  padding-bottom: ${spacing[24]};
  width: 100%;

  ${media.desktopLarge`
      width: 392px;
  `}
`;

const StyledName = styled(Display)`
  text-align: left;
  padding-bottom: ${spacing[12]};
`;

const ProductDetailsContainer = styled(Flex)`
  border: 1px solid ${grey[40]};
  border-left-width: 0;
  border-right-width: 0;
`;

const ErrorWrapper = styled(Container)<{ error?: ApolloError }>`
  width: 100%;
  margin-top: ${({ error }) => (error ? `-${spacing[8]}` : '0')};
  margin-bottom: ${spacing[24]};
  text-align: left;
`;

const ProductInfo = styled(Flex)`
  width: 100%;
`;
