import type { TypeComponentImageFields } from '@pelotoncycle/page-builder';
import type Contentful from 'contentful';
import { Locale } from '@peloton/internationalize/models/locale';
import type { ProductRecommendationsCohort } from '@content/client/www/product-recommendations/models';
import type {
  ProductGridProps,
  ImageReference,
} from '@ecomm/product-recommendations/models/ProductGridProps';

const toAssetFromUrl = (url?: string) => {
  if (!url) {
    return undefined;
  }

  return {
    fields: {
      file: {
        url,
      },
    },
  } as Contentful.Asset;
};

// This helper is used to convert an ImageReference into the legacy image fields which can be passed to a Media component
const toImageFields = (image: ImageReference): TypeComponentImageFields => {
  return {
    name: image.alt,
    alt: image.alt,
    mobile: toAssetFromUrl(image.mobile)!,
    tablet: toAssetFromUrl(image.tablet),
    desktop: toAssetFromUrl(image.desktop),
  };
};

export const toCohortFromProductGrid = (
  props: ProductGridProps,
  name: string,
  locale: Locale,
): ProductRecommendationsCohort => {
  const products = props.products
    ? props.products.map(product => {
        return {
          slug: product.slug[locale] || product.slug[Locale.EnglishUnitedStates],
          pill: product.optionalFields?.pillText,
          pillTheme: product.optionalFields?.pillTheme,
          factoid: product.optionalFields?.shortDescription,
          altInCartDescription: product.optionalFields?.altInCartDescription,
          factoidEyebrow: product.optionalFields?.descriptionEyebrow,
          priceDisplay: product.optionalFields?.priceOverride,
          financingText: product.optionalFields?.financingText,
          overrideImageUrl: product.optionalFields?.image?.mobile,
          overrideName: product.optionalFields?.nameOverride,
          tags: product.optionalFields?.tags,
          packageContentsDescription: product.optionalFields?.packageContentsDescription,
          packageContents: (product.optionalFields?.packageContents || []).map(item => ({
            image: toImageFields(item.image),
            description: item.description,
          })),
        };
      })
    : [];

  const marketingTiles = (props.marketingTiles || []).map(tile => {
    return {
      name: tile.name,
      url: tile.link.url,
      image: toImageFields(tile.image),
      title: tile.title,
      body: tile.body,
      tags: tile.tags,
      priceDisplay: tile.priceDisplay,
      pills: tile.pills,
    };
  });

  const marketingTilesWithModal = (props.marketingTilesWithModal || []).map(tile => {
    return {
      backgroundImage: toImageFields(tile.backgroundImage),
      supportingImage: toImageFields(tile.supportingImage),
      text: tile.text,
      ctaText: tile.ctaText,
      modalBody: tile.modalBody,
      modalName: tile.modalName,
      modalSupport: tile.modalSupport,
      tags: tile.tags,
    };
  });

  return {
    cohortKeyName: name,
    headline: props.headline,
    subHeadline: props.subHeadline,
    eyebrow: props.eyebrow,
    position: 'top',
    products,
    marketingTiles,
    marketingTilesWithModal,
    treatment: props.treatment,
  };
};
