import { useMemo } from 'react';
import { useLocale, toCountryFromLocale, toCurrency } from '@peloton/internationalize';
import { Locale } from '@peloton/internationalize/models/locale';
import type {
  AccessoryBundleCommercetools,
  AccessoryCommerceTools,
} from '@ecomm/pg-shop-accessories-display/models';
import type { CommercetoolsPackage } from '@ecomm/shop-configuration';
import { createNewCommercetoolsClient } from '../apollo/createCommercetoolsClient';
import { COMMERCETOOLS_AVAILABILITY_FF } from '../constants';
import { useIsCTAvailabilityActive } from '../hooks/useIsCTAvailabilityActive';
import { mapCTBundleToAccessoryBundle } from '../mappers/mapCTBundleToAccessoryBundle';
import { mapCTCFUtoKronosCFU } from '../mappers/mapCTCFUtoKronosCFU';
import { mapProductToAccessory } from '../mappers/mapProductToAccessory';
import type { ProductCatalogType } from '../models/main';
import type { SearchAnyProductsByKeysQuery } from '../queries/SearchAnyProductsByKeys.generated';
import { useSearchAnyProductsByKeysQuery } from '../queries/SearchAnyProductsByKeys.generated';
import { isAccessoryBundleCT } from '../utils/isAccessoryBundleCT';
import { isCfuPackage } from '../utils/isCfuPackage';

export type BucketedProducts = {
  accessories: Record<string, AccessoryCommerceTools>;
  bundles: Record<string, AccessoryBundleCommercetools>;
  cfuPackages: Record<string, CommercetoolsPackage>;
};

type Context = Record<string, boolean>;

// TODO: This does not handle legacy availability becuase we need to fetch an unknown number of CFUs
const useProductsByKeys = (keys: string[]) => {
  const locale = useLocale();
  const country = toCountryFromLocale(locale);
  const acceptLanguage = [locale, Locale.Default];
  const client = useMemo(() => createNewCommercetoolsClient(), []);

  const isCTAvailabilityActive = useIsCTAvailabilityActive();
  const { loading, error, data } = useSearchAnyProductsByKeysQuery({
    fetchPolicy: 'cache-first',
    variables: {
      where: toWhereClause(keys),
      acceptLanguage,
      currency: toCurrency(country),
      country,
    },
    client,
    notifyOnNetworkStatusChange: true,
  });

  const products = useMemo<BucketedProducts | undefined>(() => {
    if (!data) {
      return undefined;
    }

    const context = {
      [COMMERCETOOLS_AVAILABILITY_FF]: isCTAvailabilityActive,
    };

    return bucketProductsByType(data, locale, context);
  }, [data, locale, isCTAvailabilityActive]);

  return { loading, error, products };
};

export const toWhereClause = (keys: string[]) =>
  keys.map(key => `key="${key}"`).join(' or ');

export const bucketProductsByType = (
  data: SearchAnyProductsByKeysQuery,
  locale: Locale,
  context: Context,
): BucketedProducts => {
  const bucketedProducts: BucketedProducts = {
    accessories: {},
    bundles: {},
    cfuPackages: {},
  };

  for (const product of data.products.results as ProductCatalogType[]) {
    if (!product.key) {
      continue;
    }

    const isBundle = isAccessoryBundleCT(product);

    if (isBundle) {
      if (isCfuPackage(product)) {
        bucketedProducts.cfuPackages[product.key] = mapCTCFUtoKronosCFU(
          product,
          locale,
          context,
        ).catalog.packageBySlug;
      } else {
        bucketedProducts.bundles[product.key] = mapCTBundleToAccessoryBundle(
          product,
          locale,
          context,
        );
      }
    } else {
      bucketedProducts.accessories[product.key] = mapProductToAccessory(
        product,
        locale,
        context,
      );
    }
  }

  return bucketedProducts;
};

export default useProductsByKeys;
